import { useEffect, useState, useRef } from "react";

import asyncAPICall from "../../util/apiWrapper";
import useDeepEffect from "../hooks/useDeepEffect";
import letterHead from "../../img/letter-head.png";
import letterFooter from "../../img/letter-footer.png";
import naid from "../../img/naid.png";
import newR2 from "../../img/new-r2.svg";
import { successfulToast } from "../../util/toastNotifications";

function DisplayDocuments(props) {
  const [searchInput, setSearchInput] = useState("");
  const [companyResults, setCompanyResults] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [displayedCompanyName, setDisplayedCompanyName] = useState("");

  const buttonNameRef = useRef("Submit");

  const {
    company,
    weight,
    equipmentDesc,
    dateReceived,
    issueDate,
    codId,
    setCompany,
    setWeight,
    setEquipmentDesc,
    setDateReceived,
    setIssueDate,
    setGenerateCod,
    setShowModal,
  } = props;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (companyOptions?.length > 0) {
      asyncAPICall(
        "/organization/add",
        "POST",
        {
          cv_id: company.cvid,
          name: company.cvname,
          cv_code: company.cvcode,
          cv_option: company.userdefined1,
          address: "",
          city: "",
          state: "",
          zip_code: "",
          phone: "",
          active: true,
        },
        null,
        (data) => console.log(data),
        (err) => err,
        true
      );
    }

    setGenerateCod(true);
    successfulToast("Manual COD Generated Successfully!");
    return setShowModal(false);
  };

  const handleCompanySearch = (e) => {
    e.preventDefault();

    asyncAPICall(
      `/company/search/${searchInput}`,
      "GET",
      null,
      null,
      (data) => {
        const dataArray = data.report_data;

        handleSetResults(dataArray);
      },
      (err) => console.warn("Company Search Error: ", err),
      true
    );
  };

  const handleSelectCompany = (e) => {
    const companyObject = companyResults.filter((cR) => {
      return cR.cvid === e.target.value;
    });
    setCompany(companyObject[0]);
  };

  const handleCompanyReset = (e) => {
    e.preventDefault();

    setSearchInput("");
    setCompanyResults([]);
    setCompanyOptions([]);
    setCompany("Company");
  };

  const handleSetResults = (dataArray) => {
    const options = dataArray.map((result) => {
      return (
        <option key={result.cvid} value={result.cvid}>
          {result.cvname}
        </option>
      );
    });
    setCompanyOptions(options);
    setCompanyResults(dataArray);
  };

  const handleSetEquipmentDesc = (value) => {
    if (value.includes(",")) {
      const splitArray = value.split("\n");
      const joinedString = splitArray.join(" ");

      return setEquipmentDesc(joinedString);
    } else {
      const splitArray = value.split("\n");
      const joinedString = splitArray.join(", ");

      return setEquipmentDesc(joinedString);
    }
  };

  function SubmitButton() {
    if (
      company !== "Company" &&
      weight !== "Enter Weight in Pounds  (Numbers Only)" &&
      equipmentDesc !== "Equipment Description" &&
      dateReceived !== "Date Received" &&
      issueDate !== "Issue Date"
    ) {
      return (
        <button onClick={(e) => handleSubmit(e)}>
          {buttonNameRef.current}
        </button>
      );
    } else {
      return <button disabled>Submit</button>;
    }
  }

  const renderPage = () => {
    return (
      <div>
        <div className="headers">
          <h1>Certificate of Destruction</h1>
          <h3>Issued to</h3>
          <div className="search-and-note-container">
            {company === "Company" ? (
              <div className="search-note">* Enter at least 2 characters</div>
            ) : null}
            <div className="company-search-container">
              {company === "Company" ? (
                <>
                  <div className="company-search-wrapper">
                    <input
                      name="company"
                      type="text"
                      placeholder="Company Name"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    {searchInput?.length > 1 ? (
                      <button
                        className="company-search-button"
                        onClick={(e) => handleCompanySearch(e)}
                      >
                        Search
                      </button>
                    ) : (
                      <button className="company-search-button" disabled>
                        Search
                      </button>
                    )}
                  </div>
                  <select
                    defaultValue={"default-option"}
                    name="company"
                    onChange={(e) => handleSelectCompany(e)}
                  >
                    <option value="default-option">
                      {/* {"Search Results"} {"("}
                      {companyResults?.length}
                      {")"} */}
                      {"Search Results"} {"("}
                      {companyOptions?.length > 0 &&
                      typeof companyOptions[0]?.props?.value !== "undefined"
                        ? companyOptions?.length
                        : companyOptions?.length > 0 &&
                          typeof companyOptions[0]?.props?.value === "undefined"
                        ? "None Found"
                        : "0"}
                      {")"}
                    </option>
                    {companyOptions}
                  </select>
                </>
              ) : (
                <>
                  <div className="chosen-company-wrapper">
                    <div className="chosen-company-code">
                      {/* {displayedCompanyName?.length > 36
                        ? displayedCompanyName.slice(0, 36) + "..."
                        : displayedCompanyName} */}
                      {displayedCompanyName && displayedCompanyName?.length > 24
                        ? displayedCompanyName.slice(0, 24) + "..."
                        : !displayedCompanyName &&
                          company?.cvname &&
                          company?.cvname?.length > 24
                        ? company.cvname.slice(0, 24) + "..."
                        : company?.organization?.name &&
                          company?.organization?.name?.length > 24
                        ? company.organization.name.slice(0, 24) + "..."
                        : displayedCompanyName
                        ? displayedCompanyName
                        : company?.cvname
                        ? company?.cvname
                        : company.organization.name}
                    </div>
                    <button
                      className="company-reset-button"
                      onClick={(e) => handleCompanyReset(e)}
                    >
                      Clear
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <h5>Certificate of Destruction:</h5>

        <p>
          TAMS hereby certifies that the electronic computer & or peripheral
          equipment received at TAMS’ facility located in Lindon, Utah has been
          de-manufactured and sent on to appropriate recycling facilities for
          final disposition. No such equipment was disposed of in a manner that
          is contrary to the applicable laws and TAMS’ current certifications.
          TAMS reserves the right to reuse any item in part or in whole in
          compliance with the core value of reuse as the optimal environmental
          advantage.
        </p>

        <p>
          TAMS warranties the complete destruction and disposition of this
          material. This destruction process is in full compliance with but not
          limited to the following standards: NAID AAA (unless opted-out), R2v3,
          ISO 14001:2015, ISO 45001:2018, ISO 9001:2015 & PCI DSS. These
          standards ensure the protection of data, employees, and the
          environment.
        </p>

        <div className="Equipment-info">
          <div className="input-fields">
            {buttonNameRef.current === "Submit" ? (
              <input
                type="text"
                placeholder={
                  weight === "Enter Weight" ? "Enter Weight" : weight
                }
                onChange={(e) => setWeight(e.target.value)}
              />
            ) : (
              <p>
                <strong>for {weight} LBS of surplus electronic material</strong>
              </p>
            )}

            {buttonNameRef.current === "Submit" ? (
              <textarea
                type="text"
                placeholder={
                  equipmentDesc === "Equipment Description"
                    ? "Equipment Description"
                    : equipmentDesc
                }
                onChange={(e) => handleSetEquipmentDesc(e.target.value)}
              />
            ) : (
              <p>({equipmentDesc})</p>
            )}

            {buttonNameRef.current === "Submit" ? (
              <input
                type="text"
                placeholder={
                  dateReceived === "Date Received"
                    ? "Date Received"
                    : dateReceived
                }
                onChange={(e) => setDateReceived(e.target.value)}
              />
            ) : (
              <p>Received on {dateReceived}</p>
            )}

            {buttonNameRef.current === "Submit" ? (
              <input
                type="text"
                placeholder={
                  issueDate === "Issue Date" ? "Issue Date" : issueDate
                }
                onChange={(e) => setIssueDate(e.target.value)}
              />
            ) : (
              <p>Issued {issueDate}</p>
            )}
          </div>

          <SubmitButton />
        </div>
        <div className="tams-authorization">
          <div className="left-footer">
            <div className="tams-and-cert-num">
              <div className="tams-certified">
                <i className="fas fa-check"></i>
                <h4>TAMS, LLC Certified</h4>
              </div>
              <div className="cert-num-and-uuid">
                <h5>Cerificate # </h5>
                <div className="uuid-wrapper">{codId}</div>
              </div>
            </div>
          </div>

          <div className="right-footer">
            <div className="signature-line">
              <h4>Curtis Nelson</h4>
              <hr />
              <h5>TAMS, LLC, Authorization</h5>
            </div>

            <div className="logos">
              <img src={newR2} className="r2" alt="r2" />
              <img src={naid} className="naid-certified" alt="naid-certified" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  useDeepEffect(() => {
    const options = companyResults.map((result) => {
      return (
        <option key={result.cvid} value={result.cvid}>
          {result.cvname}
        </option>
      );
    });
    setCompanyOptions(options);
  }, [companyResults]);

  useDeepEffect(() => {
    if (company?.cvname) {
      const newCompanyName = company.cvname;
      setDisplayedCompanyName(newCompanyName);
    }
  }, [company]);

  useEffect(() => {
    if (!weight) {
      setWeight("Enter Weight");
    }
    if (!equipmentDesc) {
      setEquipmentDesc("Equipment Description");
    }
    if (!issueDate) {
      setIssueDate("Issue Date");
    }
    // eslint-disable-next-line
  }, [weight, equipmentDesc, issueDate]);

  return (
    <div className="display-documents-container">
      <div className="display-documents">
        <img src={letterHead} alt="letter-head" />
        <div className="letter-body">
          {renderPage()}

          <p className="footer">TAMS, LLC -- 1126 W 700 N, Lindon, UT, 84042</p>
        </div>

        <img src={letterFooter} className="footer-img" alt="letter-footer" />
      </div>
    </div>
  );
}

export { DisplayDocuments };
