import { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";

import Cookies from "js-cookie";
import asyncAPICall from "../../util/apiWrapper";
import useDeepEffect from "../hooks/useDeepEffect";
import {
  errorToastNotification,
  successfulToast,
} from "../../util/toastNotifications";
import LoadingSpinner from "../LoadingSpinner";

const AccessManagement = (props) => {
  const [deactivated, setDeactivated] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState({});
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [emailError, setEmailError] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [companyResults, setCompanyResults] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [displayedCompanyName, setDisplayedCompanyName] = useState("");
  const [loading, setLoading] = useState("loaded");
  const userRole = Cookies.get("user_role");

  const editId = props.userToEdit.user_id;

  const { loggedInUser } = props;

  const validateEmail = () => {
    const body = { email: email };

    if (email === editEmail) return;

    asyncAPICall(
      "/check_email",
      "POST",
      body,
      (res) => {
        setEmailError(res.status);
      },
      null,
      (err) => console.log("validate email error:", err)
    );
  };

  const renderEmailMessage = () => {
    if (emailError === 0) {
      return <div className="email-placeholder-message">Placeholder</div>;
    } else if (email?.length === 0) {
      return <div className="email-placeholder-message">Placeholder</div>;
    } else if (emailError < 400 || email === editEmail) {
      return <div className="email-success-message">Valid Email!</div>;
    } else {
      return <div className="email-error-message">Invalid/Duplicate Email</div>;
    }
  };

  const handleSubmit = (userId, e) => {
    e.preventDefault();
    setLoading("loading");

    if (
      companyOptions?.length > 0 ||
      editId ||
      (loggedInUser?.organization?.cv_code !== "TAMSLLC" &&
        loggedInUser?.role === "admin")
    ) {
      asyncAPICall(
        "/organization/add",
        "POST",
        {
          cv_id: companyName?.cvid
            ? companyName.cvid
            : companyName.organization.cv_id,
          name: companyName?.cvname
            ? companyName.cvname
            : companyName.organization.name,
          cv_code: companyName?.cvcode
            ? companyName.cvcode
            : companyName.organization.cv_code,
          cv_option:
            companyName?.userdefined1 && companyName?.cvcode !== "TAMSLLC"
              ? companyName?.userdefined1
              : companyName?.cvcode !== "TAMSLLC" && !companyName?.userdefined1
              ? "N/A"
              : companyName?.organization?.cv_option,
          address: "",
          city: "",
          state: "",
          zip_code: "",
          phone: "",
          active: true,
        },
        null,
        (data) => {
          const company = companyName?.cvname
            ? companyName?.cvname
            : companyName?.organization?.name
            ? companyName?.organization?.name
            : "";
          if (company === "") {
            console.warn('Error: companyName = ""');
            return;
          }
          const body = {
            user_id: userId,
            org_id: data.org_id,
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: userId ? null : uuid(),
            role: role,
            active: !deactivated,
            company: company,
          };

          const endpoint = userId ? "/user/update" : "/user/add";

          if (userId) {
            const decision = window.confirm(
              "Are you sure you want to edit the user?"
            );
            if (decision) {
              sendData(body, endpoint);
            } else {
              setLoading("loaded");
            }
          } else {
            sendData(body, endpoint);
          }
        },
        (err) => console.error("Organization Add Error: ", err),
        true
      );
    }

    function sendData(body, endpoint) {
      asyncAPICall(
        endpoint,
        "POST",
        body,
        null,
        (data) => {
          if (userId) {
            props.handleUpdateUser(data);
            successfulToast("User Updated Succesfully!");
          } else {
            props.handleNewUser(data);
            successfulToast("User Created Succesfully!");
          }

          return props.setShowModal(false);
        },
        (err) => {
          errorToastNotification("Error Creating/Editing User");
          console.log("user error: ", err);
        }
      );
    }
  };

  const handleCompanySearch = (e) => {
    e.preventDefault();

    asyncAPICall(
      `/company/search/${searchInput}`,
      "GET",
      null,
      null,
      (data) => {
        const dataArray = data.report_data;

        handleSetResults(dataArray);
      },
      (err) => console.warn("Company Search Error: ", err),
      true
    );
  };

  const handleSetResults = (dataArray) => {
    const options = dataArray.map((result) => {
      return (
        <option key={result.cvid} value={result.cvid}>
          {result.cvname}
        </option>
      );
    });
    setCompanyOptions(options);
    setCompanyResults(dataArray);
  };

  const handleSelectCompany = (e) => {
    e.preventDefault();

    const companyObject = companyResults.filter((cR) => {
      return cR.cvid === e.target.value;
    });

    const company = companyObject[0]?.cvname
      ? companyObject[0]?.cvname
      : companyObject[0]?.organization
      ? companyObject[0]?.organization?.name
      : "";

    setCompanyName(companyObject[0]);

    setDisplayedCompanyName(company);
  };

  const handleCompanyReset = (e) => {
    e.preventDefault();

    setSearchInput("");
    setCompanyResults([]);
    setDisplayedCompanyName("");
    setCompanyOptions([]);
    setCompanyName({});
  };

  const handleActivationChange = (e) => {
    e.preventDefault();

    setDeactivated((d) => !d);
  };

  useDeepEffect(() => {
    const company = companyName?.cvname
      ? companyName?.cvname
      : companyName?.organization
      ? companyName?.organization?.name
      : "";
    if (
      !displayedCompanyName &&
      (companyName?.cvname || companyName?.organization)
    ) {
      setDisplayedCompanyName(company);
    }
  }, [displayedCompanyName, companyName]);

  useDeepEffect(() => {
    if (props.userToEdit.first_name) {
      setFirstName(props.userToEdit.first_name);
      setLastName(props.userToEdit.last_name);
      setCompanyName(props.userToEdit);
      setEmail(props.userToEdit.email);
      setRole(props.userToEdit.role);
      setDeactivated(!props.userToEdit.active);
      setEditEmail(props.userToEdit.email);
    }
  }, [props]);

  useDeepEffect(() => {
    const loggedInOrganization = loggedInUser?.organization;
    if (loggedInOrganization?.cv_code !== "TAMSLLC") {
      const company = loggedInOrganization.name;

      setCompanyName(loggedInUser);

      setDisplayedCompanyName(company);
    }
  }, [loggedInUser?.organization]);

  useEffect(() => {
    setEmailError(0);
  }, [email]);

  useEffect(() => {
    return () => setLoading("loaded");
  }, []);

  useEffect(() => {
    if (role === "super-admin") {
      if (
        loggedInUser.role === "super-admin" &&
        loggedInUser.user_id !== editId &&
        props.showModal
      ) {
        errorToastNotification(
          "Warning: You have chosen Super Admin role for new user"
        );
      }
    }
  }, [role, editId, loggedInUser, props.showModal]);

  return (
    <form className="user-form">
      <div className={editId ? "edit-user-container" : "add-user-container"}>
        <div className="top-box">
          <h1>{editId ? "Edit User" : "Add User"}</h1>
          <i className="fas fa-times fa-2x" onClick={props.setShowModal}></i>
        </div>
        <div className="bottom-box">
          <div className="left-side">
            <div className="left-side-top">
              <label htmlFor="firstname">First Name</label>
              <input
                name="firstname"
                value={firstName}
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
              />

              <label htmlFor="lastname">Last Name</label>
              <input
                name="lastname"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />

              <label htmlFor="company">Company</label>
              {props.cvCode === "TAMSLLC" && (
                <>
                  <div className="company-search-container">
                    {!companyName?.cvname &&
                    !companyName?.organization?.name ? (
                      <>
                        <div className="company-search-wrapper">
                          <input
                            name="company"
                            type="text"
                            placeholder="Enter at least 2 characters"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                          {searchInput?.length > 1 ? (
                            <button
                              className="company-search-button"
                              onClick={(e) => handleCompanySearch(e)}
                            >
                              Search
                            </button>
                          ) : (
                            <button className="company-search-button" disabled>
                              Search
                            </button>
                          )}
                        </div>
                        <select
                          defaultValue={"default-option"}
                          name="company"
                          onChange={(e) => handleSelectCompany(e)}
                        >
                          <option value="default-option">
                            {"Search Results"} {"("}
                            {companyOptions?.length > 0 &&
                            typeof companyOptions[0]?.props?.value !==
                              "undefined"
                              ? companyOptions?.length
                              : companyOptions?.length > 0 &&
                                typeof companyOptions[0]?.props?.value ===
                                  "undefined"
                              ? "None Found"
                              : "0"}
                            {")"}
                          </option>
                          {companyOptions?.length > 0 &&
                          typeof companyOptions[0]?.props?.value !== "undefined"
                            ? companyOptions
                            : null}
                        </select>
                      </>
                    ) : (
                      <>
                        <div className="chosen-company-wrapper">
                          <div className="chosen-company-code">
                            {displayedCompanyName &&
                            displayedCompanyName?.length > 24
                              ? displayedCompanyName.slice(0, 24) + "..."
                              : !displayedCompanyName &&
                                companyName?.cvname &&
                                companyName?.cvname?.length > 24
                              ? companyName.cvname.slice(0, 24) + "..."
                              : companyName?.organization?.name &&
                                companyName?.organization?.name?.length > 24
                              ? companyName.organization.name.slice(0, 24) +
                                "..."
                              : displayedCompanyName
                              ? displayedCompanyName
                              : companyName?.cvname
                              ? companyName?.cvname
                              : companyName.organization.name}
                          </div>
                          <button
                            className="company-reset-button"
                            onClick={(e) => handleCompanyReset(e)}
                          >
                            Clear
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              {props.cvCode !== "TAMSLLC" && (
                <>
                  <div className="company-code">
                    {companyName?.organization?.name}
                  </div>

                  {editId && (
                    <div className="client-message">
                      Please contact Tams to change Company
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="left-side-bottom">
              <label htmlFor="email">Email</label>
              <input
                name="email"
                className="email-input"
                type="text"
                value={email}
                autoComplete="new-password"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={validateEmail}
              />
              {renderEmailMessage()}
            </div>
          </div>

          <div className="right-side">
            <div className="role-wrapper">
              <label htmlFor="role" className="header">
                Role in Portal
              </label>

              <select
                value={role}
                name="role"
                onChange={(e) => setRole(e.target.value)}
              >
                {userRole === "super-admin" && editId ? (
                  <>
                    <option value="super-admin">Super Admin</option>
                    <option value="admin">Client Admin</option>
                  </>
                ) : userRole === "admin" && editId ? (
                  <>
                    <option value="admin">Client Admin</option>
                    <option value="user">Client User</option>
                  </>
                ) : userRole === "super-admin" ? (
                  <>
                    <option value="default-option">Choose Role:</option>
                    <option value="super-admin">Super Admin</option>
                    <option value="admin">Client Admin</option>
                  </>
                ) : userRole === "admin" ? (
                  <>
                    <option value="default-option">Choose Role:</option>
                    <option value="admin">Client Admin</option>
                    <option value="user">Client User</option>
                  </>
                ) : null}
              </select>
            </div>

            <div className="activate-save-wrapper">
              <button
                onClick={handleActivationChange}
                className={`default-button ${
                  deactivated ? "deactivated" : "activated"
                }`}
              >
                {deactivated ? "Deactivated" : "Activated"}
              </button>

              <button
                disabled={
                  firstName.length < 1 ||
                  lastName.length < 1 ||
                  (!companyName?.cvname && !companyName?.organization?.name) ||
                  email.length < 1 ||
                  role.length < 1 ||
                  (emailError > 300 && email !== editEmail) ||
                  role === "role"
                }
                className="submit"
                onClick={(e) =>
                  editId ? handleSubmit(editId, e) : handleSubmit(false, e)
                }
              >
                Save
              </button>
              <LoadingSpinner loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AccessManagement;
