import Modal from "react-modal";

import AccessManagementForm from "../forms/AccessManagementForm";

Modal.setAppElement("#root");

const AddUserModal = (props) => {
  return (
    <Modal
      isOpen={props.showModal}
      onRequestClose={() => props.setShowModal(false)}
      style={{
        overlay: {
          backdropFilter: "blur(2px)",
          backgroundColor: "rgb(49, 53, 62, 0.5)",
        },
        content: {
          positon: "absolute",
          width: "500px",
          height: "fit-content",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "0",
          border: "none",
          borderRadius: "15px",
        },
      }}
    >
      <AccessManagementForm
        userToEdit={props.userToEdit}
        orgId={props.orgId}
        orgs={props.orgs}
        showModal={props.showModal}
        setShowModal={props.setShowModal}
        handleNewUser={props.handleNewUser}
        handleUpdateUser={props.handleUpdateUser}
        cvCode={props.cvCode}
        loggedInUser={props.loggedInUser}
      />
    </Modal>
  );
};

export default AddUserModal;
